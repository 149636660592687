import React from "react";
import theme from "theme";
import { Theme, Text, Section, Image, Link, Box, Hr, Input, Button, Icon, LinkBox, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { FaAcquisitionsIncorporated, FaFacebook, FaInstagram, FaTwitterSquare, FaGithub, FaYoutube } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section sm-position="static" display="none">
			<Text margin="0px 0px 0px 0px" sm-font="bold 30px --fontFamily-googleLora" sm-text-align="center" sm-color="rgb(80 101 39)">
				SHREE RAM RAGHUVAR ASHRAM
			</Text>
		</Section>
		<Components.Header2 width="auto">
			<Override slot="text" sm-text-align="center" md-text-align="center">
				REG NO: IN-UP38492236005292U
			</Override>
			<Override slot="button" type="link" sm-text-align="center" href="/index2" />
			<Override slot="quarklycommunityKitMobileSidePanelOverride2">
				Menu
			</Override>
			<Override slot="icon" category="fa" icon={FaAcquisitionsIncorporated} />
			<Override slot="box2" />
			<Override slot="box1" sm-justify-content="center" sm-text-align="center" />
			<Override slot="text1" sm-text-align="center" md-text-align="center" lg-text-align="center">
				SHREE RAM RAGHUVAR ASHRAM
			</Override>
			<Override
				slot="quarklycommunityKitMobileSidePanel"
				sm-justify-content="center"
				sm-text-align="center"
				md-text-align="center"
				md-justify-content="center"
			/>
			<Override slot="linkBox" md-text-align="center" />
			<Override slot="SectionContent" md-justify-content="center" />
		</Components.Header2>
		<Section
			padding="140px 0 140px 0"
			min-height="100vh"
			lg-background="#fff"
			md-padding="96px 0 70px 0"
			sm-padding="72px 0 70px 0"
			sm-position="static"
			display="block"
			position="static"
			width="auto"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				sm-align-items="center"
				sm-display="flex"
				sm-flex-direction="row"
				sm-justify-content="center"
				lg-align-items="center"
				lg-display="flex"
				lg-flex-direction="row"
				lg-justify-content="center"
			/>
			<Image
				src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11:12:33.634Z"
				display="block"
				width="300px"
				height="400px"
				text-align="left"
				md-padding="0px 0px 0px 20px"
				sm-padding="0px 0px 0px 0px"
				box-shadow="inset 0 0 0 0 transparent"
				srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="normal 900 57px/1.2 --fontFamily-googleLora"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-transform="uppercase"
					text-align="center"
				>
					Discover Peace at Our Ashram
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--headline3"
					lg-text-align="center"
					text-align="center"
				>
					Experience tranquility at Shree Ram Raghuvar Ashram, where mindful living meets spiritual awakening in harmony with nature.
				</Text>
				<Box
					sm-flex-direction="column"
					sm-text-align="center"
					align-items="center"
					justify-content="space-between"
					flex-direction="row"
					flex-wrap="wrap"
					display="flex"
					align-content="space-around"
					padding="0px 0px 0px 40px"
				>
					<Link
						href="#"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="#FFC400"
						text-decoration-line="initial"
						font="normal bold 20px/1.5 --fontFamily-sans"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						hover-background="--color-orange"
						hover-transition="background-color 0.2s ease-in-out 0s"
						transition="background-color 0.2s ease-in-out 0s"
						flex-direction="row"
					>
						Become A Member{" "}
					</Link>
				</Box>
			</Box>
			<Image
				src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11:15:45.462Z"
				display="block"
				height="400px"
				width="300px"
				srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
		</Section>
		<Box
			display="flex"
			align-items="center"
			flex-direction="column"
			justify-content="center"
			margin="0px 0px 64px 0px"
			width="auto"
			sm-margin="0px 0px 30px 0px"
			lg-padding="0px 0px 0px 0px"
		>
			<Text
				margin="0px 0px 16px 0px"
				font="normal 900 66px/1.2 --fontFamily-googleLora"
				text-align="center"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				width="1170px"
			>
				Ashram Videos{"\n\n"}
			</Text>
			<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="normal 300 22px/1.5 --fontFamily-sans">
				The #1 Spiritual Retreat, Find Peace Within{"\n\n"}
			</Text>
			<Section>
				<Override slot="SectionContent" />
				<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Video-4">
					<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
					<Box
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						sm-padding="0px 0px 0px 0px"
						sm-margin="0px 0px 30px 0px"
						flex-direction="column"
						display="flex"
						width="50%"
						lg-width="100%"
					>
						<Components.QuarklycommunityKitYouTube showOverlay url="https://youtu.be/mTjvLH20HTs" font-style={false}>
							<Override slot="YouTube Button" background="--color-light" width="70px" height="70px" />
							<Override slot="YouTube Button Icon" color="--dark" />
						</Components.QuarklycommunityKitYouTube>
					</Box>
					<Box
						flex-direction="column"
						display="flex"
						width="50%"
						lg-width="100%"
						margin="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						sm-padding="0px 0px 0px 0px"
						sm-margin="0px 0px 30px 0px"
					>
						<Components.QuarklycommunityKitYouTube showOverlay url="https://youtu.be/mTjvLH20HTs" controls={false} font-style={false}>
							<Override slot="YouTube Button" background="--color-light" width="70px" height="70px" />
							<Override slot="YouTube Button Icon" color="--dark" />
						</Components.QuarklycommunityKitYouTube>
					</Box>
				</Section>
			</Section>
		</Box>
		<Components.QuarklycommunityKitCarousel width="auto">
			<Override slot="Slide Image" src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/235546783_882278456052059_2367902711404688197_n.jpg?v=2024-10-15T13:30:33.609Z" width="auto" height="auto" />
			<Override slot="Slide" width="auto" height="50% content-box" />
			<Override slot="Slide Image 1" src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11:12:33.634Z" />
			<Override slot="Slide Image 2" src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11:15:45.462Z" />
			<Override slot="Slide Image 3" src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12:24:35.363Z" />
		</Components.QuarklycommunityKitCarousel>
		<Section padding="80px 0 80px 0" width="auto">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="normal 900 66px/1.2 --fontFamily-googleLora"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="1170px"
				>
					Discover Inner Peace
					<br />
					{" "}at Our Ashram
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="normal 300 22px/1.5 --fontFamily-sans">
					Discover our ashram's origins and the values that inspire our community.
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
				height="max-content"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11:12:33.634Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/A6x9.jpg?v=2024-10-15T11%3A12%3A33.634Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Ram Chandra Ji Maharaj (Lala Ji  Maharaj)
						</Text>
						<Link
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
							target="_blank"
							href="/index1"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11:15:45.462Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/Screenshot_20210129-223236__01.jpg?v=2024-10-15T11%3A15%3A45.462Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Raguvar Dayal Ji Maharaj (Chacha Ji  Maharaj)
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					padding="7px 7px 40px 7px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12:24:35.363Z"
						border-radius="16px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						padding="0px 0px 18px 0px"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="31px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Raguvar Dayal Ji Maharaj (Chacha Ji  Maharaj)
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					padding="7px 7px 40px 7px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12:24:35.363Z"
						border-radius="16px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						padding="0px 0px 18px 0px"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="31px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Raguvar Dayal Ji Maharaj (Chacha Ji  Maharaj)
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					padding="7px 7px 40px 7px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12:24:35.363Z"
						border-radius="16px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						padding="0px 0px 18px 0px"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="31px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Raguvar Dayal Ji Maharaj (Chacha Ji  Maharaj)
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					padding="7px 7px 40px 7px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12:24:35.363Z"
						border-radius="16px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="540px"
						object-fit="cover"
						overflow="visible"
						padding="0px 0px 18px 0px"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/IMG-20210718-WA0004.jpg?v=2024-10-15T12%3A24%3A35.363Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="31px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Param Sant Satguru Mahatama Raguvar Dayal Ji Maharaj (Chacha Ji  Maharaj)
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="normal bold 20px/1.5 --fontFamily-googleLora"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Lifestory
						</Link>
					</Box>
				</Box>
			</Box>
			<Section>
				<Override slot="SectionContent" width="auto" />
				<Link
					href="#"
					text-decoration-line="initial"
					color="--dark"
					font="normal bold 20px/1.5 --fontFamily-googleLora"
					padding="12px 24px 12px 24px"
					border-radius="8px"
					background="--color-secondary"
					lg-padding="12px 20px 12px 20px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					text-align="center"
					position="static"
				>
					Learn More
				</Link>
			</Section>
		</Section>
		<Section padding="80px 0 80px 0" width="auto">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="normal bold 72px/1.2 --fontFamily-googleLora" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					TEACHINGS
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="normal bold 20px/1.5 --fontFamily-googleLora">
					A complete collection of GURU PARIVAR in the form of EBOOKS
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
				min-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1517770413964-df8ca61194a6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Book 1
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
							INR 200
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Read Book
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1529158062015-cad636e205a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Book 2
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
							INR 300
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Read Book
						</Link>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1532012197267-da84d127e765?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Book 3
						</Text>
						<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
							INR 500
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
						</Text>
						<Link
							href="#"
							text-decoration-line="initial"
							color="--dark"
							font="--lead"
							padding="12px 24px 12px 24px"
							border-radius="8px"
							background="--color-secondary"
							lg-padding="12px 20px 12px 20px"
							transition="background-color 0.2s ease-in-out 0s"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-orange"
						>
							Read Book
						</Link>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			background="rgba(237, 242, 246, 0)"
			sm-padding="60px 0 60px 0"
			quarkly-title="Schedule-8"
			width="auto"
		>
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px 30px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				align-items="center"
				display="flex"
				grid-column="1 / span 3"
				lg-grid-column="auto / auto"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="normal 900 72px/1.2 --fontFamily-googleLora"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Upcoming Events
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					md-text-align="center"
					text-align="center"
					max-width="800px"
					sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				>
					Upcoming events and schedules
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead" lg-text-align="left">
					17/10/2024  17:30 — 18:30
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Meditation Session
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					"After years of seeking peace, the Shree Ram Raghuvar Ashram provides tranquility and spiritual growth in ways you've never imagined.
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12:34:19.102Z"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Anuj Mohan Das Ji{" "}
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="--greyD1">
							Founder
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead" lg-text-align="left">
					18/10/2024  17:30 — 18:30
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Yoga Session
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1728069002339-8d04bd46940f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Ethan Tremblay
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="--greyD1">
							Yoga Instructor
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text margin="0px 0px 16px 0px" color="--darkL2" font="--lead" lg-text-align="left">
					19/10/2024  17:30 — 18:30
				</Text>
				<Text margin="0px 0px 14px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Spritual Gathering
				</Text>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
						srcSet="https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1719937206341-38a6392dfdef?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Adriana Williams
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="--greyD1">
							Manager
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal bold 56px/1.2 --fontFamily-googleLora"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Recent Events
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				A lot of events recently completed by our Ashram
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#ffdd61"
					border-radius="4px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21:20:03.764Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_42_15.png?v=2024-10-22T21%3A20%3A03.764Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Rebuilding Of Roof
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Param Sant Satguru Mahatma Raghuvar Dayal Ji Maharaj Chacha Ji Maharaj ke Chat Ka karya Karwate Hue Aap Sabke Sevak
							<br />
							Anuj Mohan{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#ffdd61"
					border-radius="4px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21:23:02.959Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/screenshot-www.facebook.com-2024.10.23-02_51_51.png?v=2024-10-22T21%3A23%3A02.959Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Blood Donation Camp
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Shree Ram Raghuvar Aashram Dwara samadhi mein RaktDaan Ka Aayojan Kiya Gaya Aap Sabke Sevak
							<br />
							<br />
							Anuj Mohan{"\n\n"}
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#ffdd61"
					border-radius="4px"
				>
					<Image
						src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12:53:35.549Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/285844455_1073696413576928_8191074711114597472_n.jpg?v=2024-10-15T12%3A53%3A35.549Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Bhandara Karyakram
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Shree Ram Raghuvar Aashram Dwara samadhi mein vishaal Bhandare ka ayojan Kiya Gaya Aap Sabka Sevak
							<br />
							<br />
							Anuj Mohan{"\n\n"}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section width="auto">
			<Override slot="SectionContent" width="auto" />
			<Link
				href="/index3"
				text-decoration-line="initial"
				color="--dark"
				font="normal bold 20px/1.5 --fontFamily-googleLora"
				padding="12px 24px 12px 24px"
				border-radius="8px"
				background="--color-secondary"
				lg-padding="12px 20px 12px 20px"
				transition="background-color 0.2s ease-in-out 0s"
				hover-transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-orange"
				text-align="center"
				position="static"
			>
				View More
			</Link>
		</Section>
		<Box
			display="flex"
			align-items="center"
			flex-direction="column"
			justify-content="center"
			margin="0px 0px 64px 0px"
			width="auto"
			sm-margin="0px 0px 30px 0px"
			padding="0px 200px 0px 200px"
			lg-padding="0px 0px 0px 0px"
		>
			<Text margin="0px 0px 16px 0px" font="normal bold 72px/1.2 --fontFamily-googleLora" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
				Our Founder
			</Text>
			<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="normal bold 20px/1.5 --fontFamily-googleLora">
				Teachings of Shree Ram Raghuvar Ashram{" "}
			</Text>
		</Box>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" width="auto">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image
					max-width="340px"
					src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12:34:19.102Z"
					md-margin="0px 0px 20px 0px"
					srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/447599544_1521643338782231_8688000631630702872_n.jpg?v=2024-10-15T12%3A34%3A19.102Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="normal bold 16px/1.5 --fontFamily-googleLora">
					Anuj Mohan Das Ji Maharaj, Founder of  Shri Ram Raghuvar Ashram
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="normal bold 42px/1.2 --fontFamily-googleLora" md-margin="0px 0px 30px 0px" />
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="normal 500 28px/1.2 --fontFamily-googleLora">
					"After years of seeking peace, the Shree Ram Raghuvar Ashram provides tranquility and spiritual growth in ways you've never imagined. The embrace of nature and profound wisdom shared here truly enriched your soul, offering a sanctuary from the bustling world and deepening path of self-discovery."
					<br />
					<br />
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" />
			</Box>
		</Section>
		<Section background="#667e3a" color="--dark" padding="64px 0 64px 0" width="auto">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box>
						<Text font="--headline2" max-width="500px" margin="10px 0 0 0" color="#ffffff">
							Join Our Spiritual Journey
						</Text>
					</Box>
					<Text
						font="normal 300 12px/1.5 --fontFamily-googleLora"
						color="#ffffff"
						letter-spacing="1px"
						text-transform="uppercase"
						margin="0"
						padding="8px 0px 0px 0px"
					>
						Stay inspired with our updates on yoga, enlightenment, and spiritual awakening.
					</Text>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" lg-width="100%">
					<Box>
						<Formspree endpoint="xeqpgrlv">
							<Box
								gap="16px"
								display="grid"
								flex-direction="row"
								flex-wrap="wrap"
								grid-template-columns="repeat(2, 1fr)"
								grid-gap="16px"
							>
								<Box sm-width="100%" display="flex" flex-direction="column" color="#ffffff">
									<Text font="--base" margin="0 0 4px 0">
										Name
									</Text>
									<Input width="100%" name="name" type="text" />
								</Box>
								<Box sm-width="100%" display="flex" flex-direction="column" color="#ffffff">
									<Text font="--base" margin="0 0 4px 0">
										Email
									</Text>
									<Input width="100%" type="email" name="email" />
								</Box>
								<Box display="flex" flex-direction="column" grid-column="1 / span 2">
									<Text font="--base" margin="0 0 4px 0" color="#ffffff">
										Message
									</Text>
									<Input as="textarea" rows="4" width="100%" name="message" />
								</Box>
								<Box display="flex" flex-direction="column" align-items="flex-start" grid-column="1 / span 2">
									<Button>
										Send
									</Button>
								</Box>
							</Box>
						</Formspree>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Footer-10" md-padding="40px 0 40px 0">
			<Text margin="0px 0px 0px 0px" />
			<Box
				display="flex"
				lg-width="100%"
				flex-direction="row"
				lg-flex-direction="row"
				justify-content="space-between"
				width="100%"
				padding="0 0px 0 0px"
				md-padding="0 0px 30px 0px"
				lg-padding="0 0px 50px 0px"
				sm-flex-direction="column"
				md-flex-direction="column"
				md-display="flex"
				md-grid-gap="52px"
			>
				<Box
					lg-margin="0px 0px 0px 0px"
					width="30%"
					display="flex"
					lg-width="50%"
					sm-width="100%"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					sm-margin="0px 0px 0px 0px"
				>
					<Section>
						<Override
							slot="SectionContent"
							width="50% content-box"
							align-items="flex-start"
							display="flex"
							flex-direction="row"
							justify-content="flex-start"
							flex-wrap="no-wrap"
						/>
						<Image
							src="https://uploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11:00:58.770Z"
							display="block"
							width="120px"
							height="85px"
							padding="0px 0px 0px 0px"
							srcSet="https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/670e4a9e1b94b1002431b398/images/logo_ashram-removebg.png?v=2024-10-15T11%3A00%3A58.770Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Text
							margin="0px 0px 0px 0px"
							font="bold 20px --fontFamily-googleLora"
							text-align="center"
							padding="19px 0px 0px 0px"
							color="rgb(80 101 39)"
						>
							SHREE RAM RAGHUVAR ASHRAM
							<br />
						</Text>
					</Section>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						grid-template-columns="repeat(5, 1fr)"
						grid-gap="16px 24px"
						md-align-self="flex-start"
						align-self="flex-start"
						padding="0px 0px 0px 35px"
						font="23px sans-serif"
						height="30px"
						align-items="center"
						flex-direction="column"
						justify-content="space-between"
						flex-wrap="wrap"
						grid-row-gap="16px"
					>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaFacebook}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaInstagram}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaTwitterSquare}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaGithub}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
						<LinkBox href="/">
							<Icon
								category="fa"
								icon={FaYoutube}
								size="24px"
								color="#5a5d64"
								hover-color="#6d32ec"
								transition="background-color 1s ease 0s"
							/>
						</LinkBox>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					grid-template-columns="repeat(4, 1fr)"
					grid-gap="36px 34px"
					lg-align-items="start"
					md-grid-template-columns="repeat(4, 1fr)"
					md-grid-gap="36px 34px"
					sm-grid-gap="36px 34px"
					sm-grid-template-columns="repeat(2, 1fr)"
					align-items="flex-start"
					flex-direction="row"
					justify-content="flex-start"
					flex-wrap="wrap"
					align-content="flex-end"
				>
					<Box
						align-items="flex-start"
						lg-align-items="flex-start"
						justify-content="space-between"
						display="flex"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						align-content="stretch"
						grid-gap="8px 0"
						lg-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Contact
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							+91-8795-742-208
						</Link>
					</Box>
					<Box
						align-items="center"
						lg-align-items="flex-start"
						justify-content="space-between"
						display="flex"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						align-content="stretch"
						grid-gap="8px 0"
						lg-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Address
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							8/223, Raghuvar Bhawan,
							<br />
							Arya Nagar , Kanpur - 208007
						</Link>
					</Box>
					<Box
						align-items="center"
						lg-align-items="flex-start"
						justify-content="space-between"
						display="flex"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="no-wrap"
						align-content="start"
						grid-gap="8px 0"
						lg-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Email
						</Text>
						<Link
							border-color="--color-primary"
							display="flex"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							margin="0px 0 0px 0"
							lg-border-width="0px 0px 0px 2px"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							abc@123.com
						</Link>
					</Box>
					<Box
						align-items="center"
						lg-align-items="flex-start"
						justify-content="space-between"
						display="flex"
						lg-flex-direction="column"
						lg-margin="0px 0px 0px 0px"
						flex-direction="column"
						flex-wrap="wrap"
						align-content="stretch"
						grid-gap="8px 0"
						lg-justify-content="start"
					>
						<Text margin="0px 0px 20px 0px" font="normal 500 20px/1.5 --fontFamily-sans">
							Donate
						</Text>
						<Link
							margin="0px 0 0px 0"
							display="flex"
							href="#"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							text-decoration-line="initial"
							color="--darkL1"
							hover-color="#6d32ec"
						>
							UPI: 
+91-8795-742-208
						</Link>
						<Link
							margin="0px 0 0px 0"
							hover-color="#6d32ec"
							href="#"
							text-decoration-line="initial"
							color="--darkL1"
							font="normal 400 16px/24px --fontFamily-googleRoboto"
							display="flex"
						>
							A/c No: 123456789
							<br />
							IFSC: UPI112233
						</Link>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 0px 0px">
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				/>
				©   2024 Developed by Complete IT Solution
			</Text>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"670e4a9e1b94b1002431b396"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});